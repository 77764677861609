import React, { useMemo } from "react"
import PageRoot from "../components/PageRoot"
import { Trans, useTranslation } from "react-i18next"
import "../style/landing.less"
import { StaticImage } from "gatsby-plugin-image"
import VideoOverlay from "../components/VideoOverlay"
import logo from "../../static/assets/images/landing/logo_v2.svg"
import {
  Anchor,
  Button,
  Col,
  Collapse,
  Layout as AntLayout,
  Row,
  Space,
} from "antd"
import {
  CheckCircleFilled,
  FieldTimeOutlined,
  FileProtectOutlined,
  MinusOutlined,
  PlaySquareOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { useAuth0 } from "@auth0/auth0-react"
import useBreakpoint from "../hooks/useBreakpoint"
import PlanSelector from "../components/customFormItems/PlanSelector"

const { Footer } = AntLayout
const { Panel } = Collapse
const { Link } = Anchor

const rootLayoutRowSettings = {
  justify: "space-around",
}

const rootLayoutColSettings = {
  xs: 22,
  sm: 22,
  md: 22,
  lg: 20,
  xl: 20,
  xxl: 16,
}

const halfWidthColSettings = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
  xl: 12,
  xxl: 12,
}

const halfWidthRtlColSettings = {
  first: {
    xs: { span: 24, order: 1 },
    sm: { span: 24, order: 1 },
    md: { span: 24, order: 1 },
    lg: { span: 12, order: 2 },
    xl: { span: 12, order: 2 },
    xxl: { span: 12, order: 2 },
  },
  second: {
    xs: { span: 24, order: 2 },
    sm: { span: 24, order: 2 },
    md: { span: 24, order: 2 },
    lg: { span: 12, order: 1 },
    xl: { span: 12, order: 1 },
    xxl: { span: 12, order: 1 },
  },
}

const thirdWidthColSettings = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 8,
  xl: 8,
  xxl: 8,
}

const rowGutter = [64, 64]

const cardVideoContainerStyle = {
  xs: {
    position: "absolute",
    width: 308,
    height: 160,
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0,
  },
  sm: {
    position: "absolute",
    width: 308,
    height: 160,
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0,
  },
  md: {
    position: "absolute",
    width: 600,
    height: 350,
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0,
  },
  lg: {
    position: "absolute",
    width: 400,
    height: 300,
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0,
  },
  xl: {
    position: "absolute",
    width: 550,
    height: 350,
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0,
  },
  xxl: {
    position: "absolute",
    width: 700,
    height: 350,
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0,
  },
}

const cardVideoStyle = {
  xs: {
    position: "absolute",
    top: 6,
    left: 33,
    width: 240,
  },
  sm: {
    position: "absolute",
    top: 6,
    left: 33,
    width: 240,
  },
  md: {
    position: "absolute",
    top: 8,
    left: 66,
    width: 468,
  },
  lg: {
    position: "absolute",
    top: 12,
    left: 50,
    width: 300,
  },
  xl: {
    position: "absolute",
    top: 8,
    left: 59,
    width: 432,
  },
  xxl: {
    position: "absolute",
    top: 18,
    left: 90,
    width: 518,
  },
}

const introContainerVideoStyle = {
  xs: {
    position: "absolute",
    height: 200,
    width: 350,
    left: 0,
    right: 0,
    margin: "auto",
  },
  sm: {
    position: "absolute",
    height: 200,
    width: 350,
    left: 0,
    right: 0,
    margin: "auto",
  },
  md: {
    position: "absolute",
    height: 350,
    width: 700,
    left: 0,
    right: 0,
    margin: "auto",
  },
  lg: {
    position: "absolute",
    height: 500,
    width: 1200,
    left: 0,
    right: 0,
    margin: "auto",
  },
  xl: {
    position: "absolute",
    height: 500,
    width: 1200,
    left: 0,
    right: 0,
    margin: "auto",
  },
  xxl: {
    position: "absolute",
    height: 500,
    width: 1200,
    left: 0,
    right: 0,
    margin: "auto",
  },
}

const introVideoStyle = {
  xs: {
    position: "absolute",
    top: 10,
    left: 95,
    width: 200,
  },
  sm: {
    position: "absolute",
    top: 10,
    left: 95,
    width: 200,
  },
  md: {
    position: "absolute",
    top: 23,
    left: 193,
    width: 399,
  },
  lg: {
    position: "absolute",
    top: 38,
    left: 332,
    width: 684,
  },
  xl: {
    position: "absolute",
    top: 38,
    left: 332,
    width: 684,
  },
  xxl: {
    position: "absolute",
    top: 38,
    left: 332,
    width: 684,
  },
}

const headerVideoContainerStyle = {
  xs: {
    display: "none",
  },
  sm: {
    display: "none",
  },
  md: {
    display: "none",
  },
  lg: {
    position: "absolute",
    bottom: 260,
    right: 0,
    width: 550,
    height: 300,
    zIndex: 0,
  },
  xl: {
    position: "absolute",
    top: 240,
    right: 0,
    width: 760,
    height: 400,
    zIndex: 0,
  },
  xxl: {
    position: "absolute",
    top: 160,
    right: 0,
    width: 1100,
    height: 400,
    zIndex: 0,
  },
}

const headerVideoOverlayStyle = {
  xs: {
    position: "absolute",
    top: 61,
    left: 283,
    width: 912,
  },
  sm: {
    position: "absolute",
    top: 61,
    left: 283,
    width: 912,
  },
  md: {
    position: "absolute",
    top: 61,
    left: 283,
    width: 912,
  },
  lg: {
    position: "absolute",
    top: 31,
    left: 140,
    width: 460,
  },
  xl: {
    position: "absolute",
    top: 43,
    left: 196,
    width: 627,
  },
  xxl: {
    position: "absolute",
    top: 61,
    left: 283,
    width: 912,
  },
}

const authorBackgroundImageStyle = {
  xs: {
    width: "700%",
    marginLeft: "-350%",
  },
  sm: {
    width: "236%",
    marginLeft: "-35%",
  },
  md: {
    width: "300%",
    marginLeft: "-150%",
  },
  lg: {
    width: "200%",
    marginLeft: "-100%",
  },
  xl: {
    width: "150%",
    marginLeft: "-25%",
  },
  xxl: {
    width: "100%",
    marginLeft: 0,
  },
}

const recapColSettings = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 6,
}

const getResponsiveStyle = (styles, breakpoint) => {
  return styles[breakpoint] || styles[Object.keys(styles)[0]]
}

const LandingPage = () => {
  const { t } = useTranslation()
  const { loginWithRedirect } = useAuth0()
  const breakpoint = useBreakpoint()

  const tags_row1 = t("landing:appRecap.tags_row1").split(",")

  const contentRecap = useMemo(
    () => (
      <section className="bg-yellow content-recap-row">
        <Row {...rootLayoutRowSettings}>
          <Col {...rootLayoutColSettings}>
            <Row justify="space-between">
              <Col {...recapColSettings}>
                <Space>
                  <PlaySquareOutlined />
                  <Trans
                    i18nKey="landing:allContentRecap.totalVideos"
                    components={[<b />]}
                  />
                </Space>
              </Col>
              <Col {...recapColSettings}>
                <Space>
                  <FileProtectOutlined />
                  <Trans
                    i18nKey="landing:allContentRecap.totalCourse"
                    components={[<b />]}
                  />
                </Space>
              </Col>
              <Col {...recapColSettings}>
                <Space>
                  <UserOutlined />
                  <Trans
                    i18nKey="landing:allContentRecap.totalTeachers"
                    components={[<b />]}
                  />
                </Space>
              </Col>
              <Col {...recapColSettings}>
                <Space>
                  <FieldTimeOutlined />
                  <Trans
                    i18nKey="landing:allContentRecap.totalTime"
                    components={[<b />]}
                  />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    ),
    []
  )

  return (
    <PageRoot title={t("label:landingPage")}>
      <div className="landing-page">
        <section className="bg-mesh pt pb">
          <div
            style={{
              ...getResponsiveStyle(headerVideoContainerStyle, breakpoint),
            }}
          >
            <StaticImage
              src="../../static/assets/images/landing/device-mac-iphone.png"
              alt="mac"
              placeholder="TRACED_SVG"
            />
            <div
              style={{
                ...getResponsiveStyle(headerVideoOverlayStyle, breakpoint),
              }}
            >
              <VideoOverlay
                placeholder={
                  <StaticImage
                    src="../../static/assets/images/landing/video-1-header-placeholder.jpg"
                    alt="mac"
                  />
                }
                source={"/assets/images/landing/video-1-header.mp4"}
              />
            </div>
          </div>
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <div className="logo">
                <img
                  src="/assets/images/subscription-premium-white.svg"
                  alt={"logo"}
                />
              </div>
              <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <h1>{t("landing:header.title")}</h1>
                  <p>{t("landing:header.content")}</p>
                  <Button type="primary" className="yellowsp">
                    <a href="#membership">{t("button:showMore")}</a>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        {contentRecap}

        <section className="pt pb">
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <Space direction="vertical" size={34}>
                <div className="bg-gray landing-card">
                  <Row gutter={rowGutter}>
                    <Col {...halfWidthRtlColSettings.first}>
                      <div
                        style={{
                          position: "relative",
                          height: getResponsiveStyle(
                            cardVideoContainerStyle,
                            breakpoint
                          ).height,
                        }}
                      >
                        <div
                          style={{
                            ...getResponsiveStyle(
                              cardVideoContainerStyle,
                              breakpoint
                            ),
                            left: 0,
                          }}
                        >
                          <StaticImage
                            src="../../static/assets/images/landing/macbook-pro.png"
                            alt="mac"
                          />
                          <div
                            style={{
                              ...getResponsiveStyle(cardVideoStyle, breakpoint),
                            }}
                          >
                            <VideoOverlay
                              placeholder={
                                <StaticImage
                                  src="../../static/assets/images/landing/video-3-all-courses-placeholder.png"
                                  alt="mac"
                                />
                              }
                              source={
                                "/assets/images/landing/video-3-all-courses.mp4"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col {...halfWidthRtlColSettings.second}>
                      <h2>
                        <Trans
                          i18nKey="landing:cards.course.title"
                          components={[<span className="highlighted" />]}
                        />
                      </h2>
                      <p>{t("landing:cards.course.content")}</p>
                      <ul>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.course.feature1")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.course.feature2")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.course.feature3")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.course.feature4")}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div className="landing-card">
                  <Row gutter={rowGutter}>
                    <Col {...halfWidthColSettings}>
                      <div
                        style={{
                          position: "relative",
                          height: getResponsiveStyle(
                            cardVideoContainerStyle,
                            breakpoint
                          ).height,
                        }}
                      >
                        <div
                          style={{
                            ...getResponsiveStyle(
                              cardVideoContainerStyle,
                              breakpoint
                            ),
                            left: 0,
                          }}
                        >
                          <StaticImage
                            src="../../static/assets/images/landing/macbook-pro.png"
                            alt="mac"
                          />
                          <div
                            style={{
                              ...getResponsiveStyle(cardVideoStyle, breakpoint),
                            }}
                          >
                            <VideoOverlay
                              placeholder={
                                <StaticImage
                                  src="../../static/assets/images/landing/video-4-human3d-placeholder.png"
                                  alt="mac"
                                />
                              }
                              source={
                                "/assets/images/landing/video-4-human3d.mp4"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col {...halfWidthColSettings}>
                      <h2>
                        <Trans
                          i18nKey="landing:cards.human3D.title"
                          components={[<span className="highlighted" />]}
                        />
                      </h2>
                      <p>{t("landing:cards.human3D.content")}</p>
                      <ul>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.human3D.feature1")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.human3D.feature2")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.human3D.feature3")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.human3D.feature4")}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div className="bg-gray landing-card">
                  <Row gutter={rowGutter}>
                    <Col {...halfWidthRtlColSettings.first}>
                      <div
                        style={{
                          position: "relative",
                          height: getResponsiveStyle(
                            cardVideoContainerStyle,
                            breakpoint
                          ).height,
                        }}
                      >
                        <div
                          style={{
                            ...getResponsiveStyle(
                              cardVideoContainerStyle,
                              breakpoint
                            ),
                            left: 0,
                          }}
                        >
                          <StaticImage
                            src="../../static/assets/images/landing/macbook-pro-cat.png"
                            alt="categories"
                          ></StaticImage>
                          <div
                            style={{
                              ...getResponsiveStyle(cardVideoStyle, breakpoint),
                              left: 0,
                            }}
                          ></div>
                        </div>
                      </div>
                    </Col>
                    <Col {...halfWidthRtlColSettings.second}>
                      <h2>
                        <Trans
                          i18nKey="landing:cards.category.title"
                          components={[<span className="highlighted" />]}
                        />
                      </h2>
                      <p>{t("landing:cards.category.content")}</p>
                      <ul>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.category.feature1")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.category.feature2")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.category.feature3")}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div className="landing-card">
                  <Row gutter={rowGutter}>
                    <Col {...halfWidthColSettings}>
                      <div
                        style={{
                          position: "relative",
                          height: getResponsiveStyle(
                            cardVideoContainerStyle,
                            breakpoint
                          ).height,
                        }}
                      >
                        <div
                          style={{
                            ...getResponsiveStyle(
                              cardVideoContainerStyle,
                              breakpoint
                            ),
                            right: 0,
                          }}
                        >
                          <StaticImage
                            src="../../static/assets/images/landing/macbook-pro.png"
                            alt="mac"
                          />
                          <div
                            style={{
                              ...cardVideoStyle,
                            }}
                            style={{
                              ...getResponsiveStyle(cardVideoStyle, breakpoint),
                            }}
                          >
                            <VideoOverlay
                              placeholder={
                                <StaticImage
                                  src="../../static/assets/images/landing/video-5-webinar-placeholder.png"
                                  alt="mac"
                                />
                              }
                              source={
                                "/assets/images/landing/video-5-webinar.mp4"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col {...halfWidthColSettings}>
                      <h2>
                        <Trans
                          i18nKey="landing:cards.webinar.title"
                          components={[<span className="highlighted" />]}
                        />
                      </h2>
                      <p>{t("landing:cards.webinar.content")}</p>
                      <ul>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.webinar.feature1")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.webinar.feature2")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.webinar.feature3")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.webinar.feature4")}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Space>
            </Col>
          </Row>
        </section>
        <section className="bg-mesh pt pb">
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <Row>
                <Col {...halfWidthRtlColSettings.second}>
                  <Space>
                    <StaticImage
                      src="../../static/assets/images/landing/appstore.svg"
                      alt="mobile app image"
                      width={50}
                    />
                    <StaticImage
                      src="../../static/assets/images/landing/playstore.svg"
                      alt="mobile app image"
                      width={50}
                    />
                  </Space>
                  <h2>
                    <Trans
                      i18nKey="landing:mobileApp.title"
                      components={[<span className="highlighted" />]}
                    />
                  </h2>
                  <p>{t("landing:mobileApp.content")}</p>
                  <Button type="primary" className="yellowsp">
                    <a href="#membership">{t("button:showMore")}</a>
                  </Button>
                </Col>
                <Col {...halfWidthRtlColSettings.first}>
                  <div style={{ marginTop: -230 }} className="single-app-img">
                    <StaticImage
                      style={{ height: 660 }}
                      src="../../static/assets/images/landing/mobile-app.png"
                      alt="mobile app image"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section className="pt pb">
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <Row gutter={rowGutter}>
                <Col className="pt-30" {...halfWidthRtlColSettings.first}>
                  <h2>
                    <Trans
                      i18nKey="landing:mobileApp2.title1"
                      components={[<span className="highlighted" />]}
                    />
                  </h2>
                  <h2>
                    <Trans
                      i18nKey="landing:mobileApp2.title2"
                      components={[<span className="highlighted" />]}
                    />
                  </h2>
                  <p>{t("landing:mobileApp2.content1")}</p>
                  <p>{t("landing:mobileApp2.content2")}</p>
                  <p>{t("landing:mobileApp2.content3")}</p>
                  <Button type="primary" className="yellowsp">
                    <a href="#membership">{t("button:showMore")}</a>
                  </Button>
                </Col>
                <Col {...halfWidthRtlColSettings.second}>
                  <VideoOverlay
                    placeholder={
                      <StaticImage
                        src="../../static/assets/images/landing/video-6-app-placeholder.jpg"
                        alt="mac"
                      />
                    }
                    source={"/assets/images/landing/video-6-app.mp4"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section>
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <h2 className="text-center mb-100">
                <Trans
                  i18nKey="landing:extraContent.title"
                  components={[<span className="highlighted" />]}
                />
              </h2>
              <Row gutter={rowGutter}>
                <Col {...thirdWidthColSettings} className="border-r-white">
                  <img
                    className="extra-content-icon mb-20"
                    src="/assets/images/landing/icon-on-demand.svg"
                    alt="icon on-demand"
                  />
                  <h2 className="mb-20">
                    <Trans
                      i18nKey="landing:extraContent.content1.title"
                      components={[<span className="highlighted" />]}
                    />
                  </h2>
                  <p>{t("landing:extraContent.content1.content")}</p>
                  <StaticImage
                    src="../../static/assets/images/landing/extra-content-on-demand.jpg"
                    alt="mobile app image"
                  />
                </Col>
                <Col {...thirdWidthColSettings} className="border-r-white">
                  <img
                    className="extra-content-icon mb-20"
                    src="/assets/images/landing/icon-certification.svg"
                    alt="icon certification"
                  />
                  <h2 className="mb-20">
                    <Trans
                      i18nKey="landing:extraContent.content2.title"
                      components={[<span className="highlighted" />]}
                    />
                  </h2>
                  <p>{t("landing:extraContent.content2.content")}</p>
                  <StaticImage
                    src="../../static/assets/images/landing/extra-content-certification.jpg"
                    alt="mobile app image"
                  />
                </Col>
                <Col {...thirdWidthColSettings}>
                  <img
                    className="extra-content-icon mb-20"
                    src="/assets/images/landing/icon-multilang.svg"
                    alt="icon multilang"
                  />
                  <h2 className="mb-20">
                    <Trans
                      i18nKey="landing:extraContent.content3.title"
                      components={[<span className="highlighted" />]}
                    />
                  </h2>
                  <p className="mb-36">
                    {t("landing:extraContent.content3.content")}
                  </p>
                  <StaticImage
                    src="../../static/assets/images/landing/extra-content-multilang.jpg"
                    alt="mobile app image"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section className="pt">
          <Row {...rootLayoutRowSettings}>
            <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10}>
              <h2 className="text-center">{t("landing:faq.title")}</h2>
              <Collapse
                defaultActiveKey={[]}
                expandIconPosition="right"
                className="faq-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? <MinusOutlined /> : <PlusOutlined />
                }
              >
                <Panel header={t("landing:faq.faq1.question")} key="faq1">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq1.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>
                <Panel header={t("landing:faq.faq2.question")} key="faq2">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq2.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>
                <Panel header={t("landing:faq.faq3.question")} key="faq3">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq3.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>

                <Panel header={t("landing:faq.faq4.question")} key="faq4">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq4.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>
                <Panel header={t("landing:faq.faq5.question")} key="faq5">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq5.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>
                <Panel header={t("landing:faq.faq6.question")} key="faq6">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq6.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>
                <Panel header={t("landing:faq.faq7.question")} key="faq7">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq7.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>
                <Panel header={t("landing:faq.faq8.question")} key="faq8">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq8.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>
                <Panel header={t("landing:faq.faq9.question")} key="faq9">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq9.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>
                <Panel header={t("landing:faq.faq10.question")} key="faq10">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq10.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </section>
        <section className="bg-mesh gradient pt">
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <div className="text-center">
                <Space direction="vertical" size={48}>
                  <StaticImage
                    src="../../static/assets/images/landing/sportscience-all.png"
                    alt="SportScience"
                  />
                </Space>
              </div>
            </Col>
          </Row>
        </section>
        {contentRecap}
        <section className="pt pb subscription-features">
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <Row {...rootLayoutRowSettings}>
                <Col {...rootLayoutColSettings}>
                  <div className="text-center">
                    <img
                      src="/assets/images/subscription-premium-white.svg"
                      alt="logo"
                      style={{ width: "70%" }}
                      className="logo"
                    />
                  </div>
                </Col>
              </Row>
              <Row gutter={rowGutter} justify="center">
                <Col {...rootLayoutColSettings} className="text-center">
                  <h2>{t("landing:membership.title")}</h2>
                  <p className="subtitle">{t("landing:membership.subtitle")}</p>
                </Col>
              </Row>
              <Row gutter={rowGutter}>
                <Col {...thirdWidthColSettings}>
                  <div className="feature-wrapper">
                    <img
                      src="/assets/images/yellow-check.svg"
                      alt="yellow-check"
                    />
                    <div>
                      <h3>
                        <Trans
                          i18nKey="landing:mainFeatures.first.title"
                          components={[<br />]}
                        />
                      </h3>
                      <p>{t("landing:mainFeatures.first.content")}</p>
                    </div>
                  </div>
                </Col>
                <Col {...thirdWidthColSettings}>
                  <div className="feature-wrapper">
                    <img
                      src="/assets/images/yellow-check.svg"
                      alt="yellow-check"
                    />
                    <div>
                      <h3>
                        <Trans
                          i18nKey="landing:mainFeatures.second.title"
                          components={[<br />]}
                        />
                      </h3>
                      <p>{t("landing:mainFeatures.second.content")}</p>
                    </div>
                  </div>
                </Col>
                <Col {...thirdWidthColSettings}>
                  <div className="feature-wrapper">
                    <img
                      src="/assets/images/yellow-check.svg"
                      alt="yellow-check"
                    />
                    <div>
                      <h3>
                        <Trans
                          i18nKey="landing:mainFeatures.third.title"
                          components={[<br />]}
                        />
                      </h3>
                      <p>{t("landing:mainFeatures.third.content")}</p>
                    </div>
                  </div>
                </Col>
                <Col {...thirdWidthColSettings}>
                  <div className="feature-wrapper">
                    <img
                      src="/assets/images/yellow-check.svg"
                      alt="yellow-check"
                    />
                    <div>
                      <h3>
                        <Trans
                          i18nKey="landing:secondaryFeatures.first.title"
                          components={[<br />]}
                        />
                      </h3>
                      <p>{t("landing:secondaryFeatures.first.content")}</p>
                    </div>
                  </div>
                </Col>
                <Col {...thirdWidthColSettings}>
                  <div className="feature-wrapper">
                    <img
                      src="/assets/images/yellow-check.svg"
                      alt="yellow-check"
                    />
                    <div>
                      <h3>
                        <Trans
                          i18nKey="landing:secondaryFeatures.second.title"
                          components={[<br />]}
                        />
                      </h3>
                      <p>{t("landing:secondaryFeatures.second.content")}</p>
                    </div>
                  </div>
                </Col>
                <Col {...thirdWidthColSettings}>
                  <div className="feature-wrapper">
                    <img
                      src="/assets/images/yellow-check.svg"
                      alt="yellow-check"
                    />
                    <div>
                      <h3>
                        <Trans
                          i18nKey="landing:secondaryFeatures.third.title"
                          components={[<br />]}
                        />
                      </h3>
                      <p>{t("landing:secondaryFeatures.third.content")}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <a name="membership" />
        <section className="pb pt bg-white plan-selector">
          <PlanSelector />
          <Row
            {...rootLayoutRowSettings}
            justify="center"
            className="button-wrapper"
          >
            <Col {...rootLayoutColSettings}>
              <Button type="primary" className="yellowsp">
                <a href="/subscribe">{t("form:label.selectPlan")}</a>
              </Button>
            </Col>
          </Row>
        </section>
      </div>
    </PageRoot>
  )
}

export default LandingPage
